.editor blockquote{
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}
.editor table{
    border-collapse: collapse;
}

.editor-wrapper{
    font-family:'Helvetica','Arial', sans-serif;
    border-radius: 10px;
    background: #ffffff;
    min-height: 400px;
    min-width: 100%;
    width: fit-content;
    height: fit-content;
    padding: 12px 10px;
}
.editor table{
    width:100%;
}
.editor td{
    height: 50px;
    padding:0 5px;
}
.popup-wrapper{
    display: inline;
    position: relative;
}
.popup{
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: fit-content;
    z-index: 1;
    border-radius: 8px;
}
.editor button{
    cursor: pointer;
}
.editor code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
[data-slate-node="element"] {
    margin: 10px 0;
}
.editor html{
    scroll-behavior: smooth;
}