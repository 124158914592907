.toolbar {
    border-radius: 10px;
    background: #ffffff;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 10px;
    row-gap: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    border: 1px solid lightgray;
}

.toolbar-grp>* {
    margin-right: 10px;
    cursor: pointer;
}

.toolbar-grp {
    margin: 0 10px;
}

.toolbar-grp select {
    height: 30px;
    border: none;
    width: 6.9rem;
}

.toolbar-grp button {
    background-color: white;
    border: none;
    opacity: 0.5;
}

.toolbar-grp .btnActive {
    opacity: 1;
}